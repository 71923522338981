import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import Card from "components/card";
import CpuTable from "components/cpuTable";
import List from "components/list";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "business"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/business.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cmonitor: file(relativePath: { eq: "cover/pcparts/monitor.png"}) { ...eyecatchImg },
    noteconnect: file(relativePath: { eq: "tanshi/note-connect.png"}) { ...normalImg },
    cultramobile: file(relativePath: { eq: "cover/others/ultramobile.png"}) { ...eyecatchImg },
    cwfh: file(relativePath: { eq: "cover/pcselection/wfh.png"}) { ...eyecatchImg },
    allBusinessJson {
      nodes {
        name
        pm
        clock
        core
        thread
        tdp
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`一流のビジネスパーソンが選ぶおすすめのノートパソコン5選`}</h1>
    <time itemProp="datePublished" dateTime="Sat Oct 12 2024 00:56:48 GMT+0900 (日本標準時)">更新日:2024年10月12日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="一流のビジネスパーソンが選ぶノートパソコン" mdxType="Image" />
    <p>{`パワーポイントでの資料作成、突然の会議でのプレゼンテーション、WebExやTeamsでのビデオ会議、顧客先への出張。およそ全ての作業でノートパソコンは必要となる。`}<strong parentName="p">{`メインのノートパソコンは仕事で方時も手放さないことから`}<em parentName="strong">{`生活PC`}</em>{`と俗に呼ばれる`}</strong>{`。`}</p>
    <p>{`サクサクと動き操作にストレスがないか？万が一落としたり、盗難にあった時は大丈夫か？モニタに繋いですぐに会議を始められるか？`}<strong parentName="p">{`生活PCの機能や性能次第で仕事のパフォーマンスやビジネス上の信頼関係の構築に大きな差が生まれてしまう`}</strong>{`。`}</p>
    <p>{`そこで本ページでは海外出張を年数回こなす大手メーカー係長クラスの社畜サラリーマン、いわゆる`}<strong parentName="p">{`一流(?)のビジネスパーソンである管理人が自信を持っておすすめできるビジネス向けノートパソコン`}</strong>{`について述べる。`}</p>
    <p>{`ビジネスに限定せず、多様な目的でパソコンを使いたい場合は`}<a href="/">{`パソコン選び方ガイドのトップページにあるパソコン診断`}</a>{`等も合わせて参考にして欲しい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "ビジネスパーソンが選ぶパソコンのスペック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%91%E3%83%BC%E3%82%BD%E3%83%B3%E3%81%8C%E9%81%B8%E3%81%B6%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF",
        "aria-label": "ビジネスパーソンが選ぶパソコンのスペック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ビジネスパーソンが選ぶパソコンのスペック`}</h2>
    <p>{`ビジネスパーソンがパソコンを購入する時に考えなければならないことは次のようなことである。`}</p>
    <List mdxType="List">
      <li>動作は高速で買替基準である４年は快適に使うことができるか？</li>
      <li>持ち運んでも疲れない程度の軽量さはあるか？</li>
      <li>パソコンと同時に持ち運ぶ変換器は減らすことができるか？</li>
      <li>落下や飲み物をこぼしたときに壊れやしないか？盗難にあった時にデータは大丈夫か？</li>
      <li>バッテリーは十分に持つか？</li>
      <li>いかなる場所であってもインターネット接続ができるか？</li>
    </List>
    <p>{`すなわち`}<strong parentName="p">{`軽量・堅牢・高速でかつ場所・状況を問わず使い続けられるか`}</strong>{`という事が重要となる。`}</p>
    <p>{`実際のパソコンを検討する前に、まずはこれらビジネスで必要とされる要件をパソコンのスペックとして落とし込むこととする。`}</p>
    <h3 {...{
      "id": "CPUはPASSMARKで10000点以上",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AFPASSMARK%E3%81%A710000%E7%82%B9%E4%BB%A5%E4%B8%8A",
        "aria-label": "CPUはPASSMARKで10000点以上 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはPASSMARKで10000点以上`}</h3>
    <p>{`簡単にはCore i5以上のパソコンがおすすめなどと言われるが、CPUは１年毎のアーキテクチャ刷新による世代差、マイナーモデルチェンジ、低消費電力版などによる複数の種類が存在するため、`}<strong parentName="p">{`正確には型番ごとに性能を比較する必要がある`}</strong>{`。`}</p>
    <p>{`「同じCore i5だけどより安いパソコンがある！」と思って買ったら実は古い世代のCPUで動作がもっさりしていた、なんて事にならないためには`}<strong parentName="p">{`総合的なCPU性能を測るベンチマークであるPASSMARKのスコアを参考`}</strong>{`にすると良い。`}<strong parentName="p">{`10000点以上あるかを目安`}</strong>{`としたい。`}</p>
    <p>{`そんなに高いスコアが必要か？と思う人もいるかもしれない。しかしビデオ会議のTeamsやWebEx、クラウド認証プラットフォームOkta、ウイルス対策ソフトなど重いアプリケーションも多い。`}<strong parentName="p">{`タフなビジネス現場で４年間パソコンを快適に使うためにはこれぐらいは必要`}</strong>{`であろう。`}</p>
    <p>{`私の会社ではMcAfeeがウイルス対策ソフトとして使われており、定期実行の折には容赦なくCPU能力が奪われ、リモート会議が止まってしまうこともある。設定の問題もあるがCPU能力に依存する部分も大きいので、`}<strong parentName="p">{`不慮のCPU低下を防ぐためにもハイスペックなCPUを選びたい`}</strong>{`。`}</p>
    <p>{`また、CPUのメーカーとしてIntelとAMDがあるが、`}<strong parentName="p"><em parentName="strong">{`明確にIntelのCoreシリーズをおすすめしたい`}</em></strong>{`。IntelはMicrosoft OfficeやAdobeなど有名ソフトベンダーに対してソフトウェア部隊を派遣し共同でチューニングを行うなどしており、動作が非常に安定するためである。`}</p>
    <p>{`下記におすすめのCPUをPASSMARKの値順にピックアップしたので参考にして欲しい。統計誤差により下位の型番が上位と逆転していることもあるのでコア数なども参考に真の実力は判断してほしい。ともあれこのリストにあるCPUならば間違いはない。`}</p>
    <CpuTable cpuData={props.data.allBusinessJson.nodes} mdxType="CpuTable" />
    <p>{`末尾の文字にH、P、Uなどのシリーズが書かれているが、HとPはグラフィックが強化されたシリーズであり、Uが低消費電力のシリーズである。長時間バッテリーを取るならU、よりハイパワーを求めるならばHやPといったイメージである。`}</p>
    <p>{`CPUの全リストをこちらで載せているので興味のある方は別途参考にして欲しい。`}</p>
    <Card {...props} title="CPU性能比較表" description="PASSMARK10000点以上を一つの目安としてパソコンを探していこう。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h3 {...{
      "id": "メモリは16GBを推奨",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF16GB%E3%82%92%E6%8E%A8%E5%A5%A8",
        "aria-label": "メモリは16GBを推奨 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは16GBを推奨`}</h3>
    <p>{`基本的にドキュメントやブラウザタブを複数開いて編集するような状況では8GBあれば事足りる。何十ものタブをブラウザで開き、動作が遅くなるケースは無いとは言えないが、その場合はメモリというよりもタスク管理に問題がありそうである。`}</p>
    <p>{`ところが、ビジネスではSlackなどのチャットアプリケーション、ウイルススキャン、Emailなど複数のアプリケーションを常駐させることが多く、メモリが圧迫されがちである。`}</p>
    <p>{`そのような環境で例えばビデオ会議で自身の資料を全体に画面シェアのようなことを行なうと、CPUのパワーやメモリが不足し、「何故かパソコンが重くなって、、よいしょっと」みたいなダサい状態となってしまう。`}</p>
    <p>{`このように重要な場面にこそパソコンは万全の状態で臨みたい。よって`}<strong parentName="p"><em parentName="strong">{`必須ではないもののメモリ16GBは安心材料`}</em></strong>{`となる。確実に価値はあるため最強のパソコンを目指すならば搭載したい。`}</p>
    <h3 {...{
      "id": "SSDは512GBあると安心",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AF512GB%E3%81%82%E3%82%8B%E3%81%A8%E5%AE%89%E5%BF%83",
        "aria-label": "SSDは512GBあると安心 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDは512GBあると安心`}</h3>
    <p>{`ストレージの容量はドキュメントの保存が中心の場合はほとんど必要とならないかもしれない。その場合は`}<strong parentName="p"><em parentName="strong">{`256GBあれば十分`}</em></strong>{`であるが、ビジネス用で使うならば`}<strong parentName="p">{`外付けハードディスクやUSBメモリをセキュリティ上の理由で避けた方が良い場合もある`}</strong>{`ため注意が必要である。`}</p>
    <p>{`しかし容量が不足してきたとしても外付けハードディスクにはセキュリティ上の懸念により移しにくく、コンプライアンス違反と見なされるリスクもある。これらを考慮すると`}<strong parentName="p"><em parentName="strong">{`512GBあるとより安心であろう`}</em></strong>{`。パソコンの容量削減のために頭を使うよりは本業に集中したいため、余裕を持ちたいところである。`}</p>
    <h3 {...{
      "id": "Windows-Proを搭載",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Windows-Pro%E3%82%92%E6%90%AD%E8%BC%89",
        "aria-label": "Windows Proを搭載 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Proを搭載`}</h3>
    <p>{`パソコンを紛失した時に企業として最も気にしなければならないことは情報の漏洩である。`}</p>
    <p>{`ID/Passwordが設定されているだけではデータ解析ツールなどで容易に内容を盗まれてしまう。実際に盗もうとする人はほとんどいないため、多分大丈夫だろうと言えるかもしれないが、`}<strong parentName="p"><em parentName="strong">{`多分で説明責任が果たせるか？`}</em>{`という観点では甚だ疑問`}</strong>{`である。`}</p>
    <p>{`そこで重要なことが`}<strong parentName="p">{`パソコン内のデータが暗号化されていること`}</strong>{`である。`}<strong parentName="p">{`Windows Proに搭載されたBitLocker`}</strong>{`を使えば、最初に解除キーを入れるひと手間があるものの暗号化によりデータの漏洩を防いでくれる。`}</p>
    <p>{`当然ノートパソコンの紛失は許されるものではなく、始末書を書く羽目にはなると思うが、「BitLockerでデータは暗号化されているため漏洩のリスクは低いです。」と言えば、上司であれ、取引先であれ`}<strong parentName="p">{`最低限説明責任を果たせているといえる`}</strong>{`だろう。`}</p>
    <p>{`また、`}<strong parentName="p">{`別の搭載理由としては`}<em parentName="strong">{`リモートデスクトップ`}</em></strong>{`が挙げられる。私はテレワーク中に開発目的で会社内にあるデスクトップパソコンに接続することが多々あるが、これはWindows Proのリモートデスクトップ接続機能を使って行っている。`}</p>
    <p>{`会社や仕事内容によっては必要はないかもしれないが、いざ必要となった時にWindows Homeだと買い替えざるを得なくなるので可能性があるならWindows Proにしておくのが無難であろう。`}</p>
    <h3 {...{
      "id": "モニターサイズは133か14インチが主流",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%AF133%E3%81%8B14%E3%82%A4%E3%83%B3%E3%83%81%E3%81%8C%E4%B8%BB%E6%B5%81",
        "aria-label": "モニターサイズは133か14インチが主流 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニターサイズは13.3か14インチが主流`}</h3>
    <p>{`サイズは13.3インチあるいは14インチ程度が携帯性と作業性を考えた時にちょうど良いサイズである。`}</p>
    <p>{`以前は13.3インチがビジネスモバイルノートとして多かった印象であるが、徐々に14インチが台頭してきている状況である。`}</p>
    <p>{`これには２つ理由が考えられ、一つはメーカーの技術力が上がりベゼル幅が狭くなったことで、以前の13.3インチと今の14インチのサイズがほとんど変わらなくなったことである。`}</p>
    <p>{`もう一つはビデオ会議が増えたことである。Teamsなどの`}<strong parentName="p"><em parentName="strong">{`ビデオ会議でメンバーがシェアしたスクリーンが小さくて見にくいことが多い`}</em></strong>{`ので、できるだけ持ち運べる範囲で大きなサイズを選ぶ人が増えたと考えられる。`}</p>
    <p>{`12.5インチはカフェなどの小さなスペースでの取り回しが行ないやすく、仕事のスタイルによっては可能であるが、どちらかといえば少数派である。`}</p>
    <h3 {...{
      "id": "1kg以下で持ち運びが容易",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1kg%E4%BB%A5%E4%B8%8B%E3%81%A7%E6%8C%81%E3%81%A1%E9%81%8B%E3%81%B3%E3%81%8C%E5%AE%B9%E6%98%93",
        "aria-label": "1kg以下で持ち運びが容易 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1kg以下で持ち運びが容易`}</h3>
    <p>{`パソコンが重いと毎日の持ち運びが辛く、ただでさえ電車通勤などで消耗している労働意欲をじわじわと削られていく。`}</p>
    <p>{`主観が多く入ってしまうが、`}<strong parentName="p"><em parentName="strong">{`持った瞬間に軽いと感じるのは1kg以下`}</em></strong>{`の時である。`}</p>
    <p>{`一方で週のほとんどはテレワークというような、持ち歩く頻度が少ないケースの場合、1.3kg程度あっても問題はないと思われる。`}</p>
    <p>{`重い方が安定するという人もいるが、軽くてもガタガタするということはなく、何より`}<strong parentName="p"><em parentName="strong">{`落とした時の衝撃も軽いため安全性が高い`}</em></strong>{`と言える。`}</p>
    <p>{`よって自身が購入する場合は価格次第であるが、持ち歩きの頻度によらず1kg未満のパソコンを恐らく選ぶであろう。`}</p>
    <Card {...props} title="モニターの選び方" description="作業用モニターとしてはノングレアで高さや角度が調節できるものを選びたい。" image="cmonitor" path="/monitor/" mdxType="Card" />
    <h3 {...{
      "id": "堅牢性が高くて落としても壊れない、防水性能がある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A0%85%E7%89%A2%E6%80%A7%E3%81%8C%E9%AB%98%E3%81%8F%E3%81%A6%E8%90%BD%E3%81%A8%E3%81%97%E3%81%A6%E3%82%82%E5%A3%8A%E3%82%8C%E3%81%AA%E3%81%84%E3%80%81%E9%98%B2%E6%B0%B4%E6%80%A7%E8%83%BD%E3%81%8C%E3%81%82%E3%82%8B",
        "aria-label": "堅牢性が高くて落としても壊れない、防水性能がある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`堅牢性が高くて落としても壊れない、防水性能がある`}</h3>
    <p>{`私はそこまで不注意な方ではないと思っているのだが、たまにパソコンを机から落とす。大抵は電源コードが立ち上がる時に絡まってやらかしてしまう。また、机でコーヒーをこぼしてパソコンが水浸しになるのもテレワーク環境ではやりがちである。`}</p>
    <p>{`このような時にパソコンは壊れていないかを毎回心配することになる。`}</p>
    <p>{`定期的にバックアップを取っている事が重要であるが、ローカルにだけ保存されているような機密データやメール履歴が入っているということも多いだろう。時によっては非常に困ることにもなる。`}</p>
    <p>{`よって`}<strong parentName="p">{`落としても濡れても壊れないということはビジネス上重要な要素`}</strong>{`である。`}</p>
    <p>{`ただし堅牢性の数値化は非常に難しい。どの企業のサイトに行っても耐荷重100kgを実現、あらゆる角度の落下試験をクリアしたなど堅牢性をアピールしており比較ができない。メーカーの品質保証担当でも他社比較は困難であろう。`}</p>
    <p>{`とはいえまずはこのような`}<strong parentName="p">{`テストを十分にしている事を確認しつつ、残りはノートパソコンのデザインが物理的に壊れにくそうか？重量は軽いか？(軽い方が落としたときの衝撃が少ない)などを判断材料に加えればよい`}</strong>{`だろう。`}</p>
    <p>{`この分野はハードウェアに強みを持つ東芝・NECなど日系大手が断トツで強い印象である。ちなみに私の妻はLenovoを使っていたがコーヒーを子供にこぼされて一瞬でキーボードが効かなくなり、上司に始末書を書いてもらう運びとなった。`}</p>
    <p>{`Lenovoが悪いと言っている訳ではないが、防水性の有無も確認しておいた方が良いだろう。`}</p>
    <h3 {...{
      "id": "必要な端子は揃っているか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%BF%85%E8%A6%81%E3%81%AA%E7%AB%AF%E5%AD%90%E3%81%AF%E6%8F%83%E3%81%A3%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "必要な端子は揃っているか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`必要な端子は揃っているか？`}</h3>
    <Image {...props} name="noteconnect" alt="ノートパソコンの端子類" mdxType="Image" />
    <p>{`端子が足りていないと外付けや変換器を使用しなければならなくなり、変換器を忘れると「誰か持っている人貸していただけませんか？」とか「忘れたのでプロジェクターに表示できないです」という状況になってしまいがちである。`}</p>
    <p>{`最初から`}<strong parentName="p">{`必要な端子が揃っていると余計な変換器を持ち運ぶ必要もなくなり安心感もある`}</strong>{`。ではビジネスをスムーズに進めるためにどの端子を用意しておけば良いのか？それは現在置かれている状況によっても変わるためが一般的には下記であろう。`}</p>
    <AccentBox title="ビジネスで必要となる端子類" mdxType="AccentBox">
  <li><span className="bold">USB TypeA</span>・・・USBメモリー、ワイヤレスマウスの通信機、ヘッドセット、YubiKeyなどセキュリティデバイスの挿し込み口等非常に広範囲で使われる重要端子。</li>
  <li><span className="bold">USB TypeC</span>・・・映像出力、高速充電、USBメモリー等マルチに活躍できる万能端子だが、これだけだと変換器が必須となる。</li>
  <li><span className="bold">HDMI</span>・・・モニターやプロジェクターにパソコンを繋げるときの主要端子。無いと変換器を持ち運ぶことになる可能性大。</li>
  <li><span className="bold">LAN端子</span>・・・有線インターネット接続をするための端子。無線が不安定な環境では非常に重宝する。</li>
  <li><span className="bold">VGA端子</span>・・・古いモニタ、プロジェクターにつなげるためにしばしば必要となる端子。まあ流石に時代遅れだから新規に購入する場合には不要か。</li>
    </AccentBox>
    <p>{`まずUSB TypeAは２つは欲しい。`}<strong parentName="p">{`USB TypeCはTypeAの上位互換であるため、TypeAでできることはTypeCでも可能`}</strong>{`である。例えばUSBメモリやヘッドセットはUSB TypeA接続のものとTypeC接続のものが両方売られているため、どちらを使用しても良い。`}</p>
    <p>{`ただTypeAの方が対応機器が多いため、`}<strong parentName="p">{`USB TypeAが２つ、TypeCが１つで合計３つぐらいが最適`}</strong>{`な組み合わせではないかと思われる。`}</p>
    <p>{`次に`}<strong parentName="p">{`HDMIは外部モニターに接続する時の主流となるため必須である`}</strong>{`。`}</p>
    <p>{`VGA端子は過去の主流端子であるため、機器の入れ替えに遅れている会社では必要となる。会社で使っている機器で必要な場合を除き不要。`}</p>
    <p><strong parentName="p">{`LAN端子は会議室等で混線して無線が不安定なことが良くあるため、あると安心感がある`}</strong>{`。また無線はCPUパワーをいくらか食い、接続速度も有線LANよりも遅いため、私は有線が使える場所では有線を使っている。`}</p>
    <h3 {...{
      "id": "バッテリーは十分に持つか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%90%E3%83%83%E3%83%86%E3%83%AA%E3%83%BC%E3%81%AF%E5%8D%81%E5%88%86%E3%81%AB%E6%8C%81%E3%81%A4%E3%81%8B%EF%BC%9F",
        "aria-label": "バッテリーは十分に持つか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`バッテリーは十分に持つか？`}</h3>
    <p>{`12時間程度バッテリー寿命があると、最悪電源を持ち歩かなくても大丈夫なためおすすめである。10時間バッテリーが持つと表示されていても実際は7時間程度のことが多いため、表記の見方には注意が必要となる。`}</p>
    <p><strong parentName="p">{`12時間バッテリーが記述があったとすると8時間は持つため、ビジネス時間中使い続けても大丈夫`}</strong>{`ということになる。余裕を持ったバッテリーを準備しておこう。`}</p>
    <h3 {...{
      "id": "インターネットへの常時接続環境は準備されているか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%8D%E3%83%83%E3%83%88%E3%81%B8%E3%81%AE%E5%B8%B8%E6%99%82%E6%8E%A5%E7%B6%9A%E7%92%B0%E5%A2%83%E3%81%AF%E6%BA%96%E5%82%99%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "インターネットへの常時接続環境は準備されているか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`インターネットへの常時接続環境は準備されているか？`}</h3>
    <p><strong parentName="p">{`格安SIMをパソコンに挿してインターネット接続が行えるLTEパソコン`}</strong>{`が人気を集めている。`}</p>
    <p>{`月々の通信コストは掛かるが、`}<strong parentName="p">{`スマホ同様の回線を使いすぐにインターネット接続ができる`}</strong>{`というのは心強い。`}</p>
    <p>{`特に訪問先のオフィスでゲスト用のWifiを訪ねる、カフェでインターネットを行うといった用途でかなり使える。`}</p>
    <p>{`Mustではないが、`}<strong parentName="p">{`出張が多い場合などは検討する価値は十分にある`}</strong>{`。`}</p>
    <p>{`ただ最近は`}<strong parentName="p">{`楽天モバイルやドコモのahamoなど多くのプランでテザリングが可能`}</strong>{`となってきているため、接続にひと手間かかるがそれで代用する方が手軽かもしれない。`}</p>
    <p>{`ただ、テザリングで代用する場合、携帯電話のバッテリーを大幅に食ってしまうため、外付けバッテリーを持ち歩くなど対策は必要である。`}</p>
    <h3 {...{
      "id": "ビジネスパーソン向けスペックのまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%91%E3%83%BC%E3%82%BD%E3%83%B3%E5%90%91%E3%81%91%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AE%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "ビジネスパーソン向けスペックのまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ビジネスパーソン向けスペックのまとめ`}</h3>
    <p>{`まとめると次のようなスペックがビジネスパーソンには向いている。これは全部入りのパソコンであるが、その中から予算や自分の使用シーンを考えて削っていくと良い。`}</p>
    <AccentBox title="ビジネスパーソン向けパソコンスペック" mdxType="AccentBox">
  <li><span className="bold">サイズ</span>・・・13.3インチまたは14インチ</li>
  <li><span className="bold">CPU</span>・・・PassMark10000点以上、最新または１世代前のCore i5,7あたり。</li>
  <li><span className="bold">メモリ</span>・・・16GB</li>
  <li><span className="bold">ストレージ</span>・・・512GB</li>
  <li><span className="bold">重量</span>・・・1kg(1000g)以下</li>
  <li><span className="bold">端子</span>・・・TypeAx2以上、TypeCx1、HDMI、LAN端子が付いている。</li>
  <li><span className="bold">OS</span>・・・Windows11 Pro</li>
  <li><span className="bold">バッテリー</span>・・・12時間以上</li>
  <li><span className="bold">Office</span>・・・Microsoft Office Home and Business</li>
  <li><span className="bold">LTE</span>・・・外回りが多い場合、テザリングで代用も一般的</li>
  <li><span className="bold">その他</span>・・・耐落下性能、防水性能などの堅牢性</li>
    </AccentBox>
    <h2 {...{
      "id": "おすすめのビジネス向けノートパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E5%90%91%E3%81%91%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "おすすめのビジネス向けノートパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめのビジネス向けノートパソコン`}</h2>
    <p>{`上の観点でおすすめできるパソコンメーカーを５選してみた。HPやDellといったメーカーもあるが、米国企業は軽量性に無頓着な印象である。`}</p>
    <p>{`私もサンフランシスコに出張することは多いが、彼らのパソコンは大抵重い。`}<strong parentName="p">{`Macbook Airは1.3kgあるがAirを名乗れるのである`}</strong>{`。その感覚である。そもそも体が彼らは大きいので無理もない話かもしれないが。`}</p>
    <p>{`そして結局`}<strong parentName="p">{`法人向けパソコンに力を入れている日本の大手企業のみが候補`}</strong>{`となった。流石に日本のビジネス事情やニーズを熟知しているだけはあるといった印象だ。ともあれ紹介していきたい。`}</p>
    <h3 {...{
      "id": "富士通--The生活PC-最軽量LIFEBOOK",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AF%8C%E5%A3%AB%E9%80%9A--The%E7%94%9F%E6%B4%BBPC-%E6%9C%80%E8%BB%BD%E9%87%8FLIFEBOOK",
        "aria-label": "富士通  The生活PC 最軽量LIFEBOOK permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`富士通 | The生活PC 最軽量LIFEBOOK`}</h3>
    <p><strong parentName="p">{`世界最軽量の700gを切るパソコンを販売`}</strong>{`しているのが富士通である。LIFEBOOKというまさに生活PCを意識したブランドを展開している。インターフェースやバッテリーも問題についても優秀であり、24時間の長時間駆動バッテリーを搭載しても900g程度の重量である。`}</p>
    <p>{`欠点らしきものも見当たらず、ノートパソコンを携帯しての移動が多い人にとっては最有力の選択肢となるだろう。`}</p>
    <p>{`特におすすめはFMV Zeroと言われるカスタムメイドモデル`}<em parentName="p">{`WU4/H1`}</em>{`である。`}<strong parentName="p"><em parentName="strong">{`14インチながら900gを切り軽量性と作業効率を両立`}</em></strong>{`しており、３０時間近い連続駆動時間とスペック面では申し分がない。`}</p>
    <p>{`更に余計なソフトのプリインストールも無く、ロゴも目立たずスタイリッシュ。他端子のサポートや強力なCPU等も含め`}<strong parentName="p"><em parentName="strong">{`ビジネスにおいて死角が無いモデル`}</em></strong>{`と言える。`}</p>
    <p>{`今一番おすすめしたいのはこのモデルである。当サイトならば限定クーポンコード ⇒ `}<b>{`SPLSJ`}</b>{`で数パーセントお得に購入できる。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=2543&murl=https%3A%2F%2Ffmv.fccl.fujitsu.com%2Fshop%2Fpc%2Fcustom%2F3242.html" target="_blank" rel="nofollow noopener"> LIFEBOOK WU4/H1</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=1284217.1&type=10" /> | クーポンコード ⇒ <b>SPLSJ</b>
    </SmallBox>
    <h3 {...{
      "id": "Dynabook--最強14インチモバイルノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Dynabook--%E6%9C%80%E5%BC%B714%E3%82%A4%E3%83%B3%E3%83%81%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "Dynabook  最強14インチモバイルノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dynabook | 最強14インチモバイルノート`}</h3>
    <p>{`液晶のシャープと世界初のノートPCを発売した東芝がタッグを組み生まれ変わった新生Dynabook。モバイルノートの長時間駆動、堅牢性、高速性と様々な箇所で技術が結集している。`}</p>
    <p><strong parentName="p">{`特にRシリーズは20時間駆動で端子数も豊富、更には最新のCPUも搭載`}</strong>{`でかつデザイン性も高い。重量も1kgを切っており欠点が全く見つからない。`}</p>
    <p>{`Pシリーズのプロセッサーを採用しているため、`}<strong parentName="p"><em parentName="strong">{`動画編集やビデオ会議などでより高いパフォーマンスを求める人にベストバイなモデル`}</em></strong>{`である。正直富士通とは甲乙つけがたい。`}</p>
    <p>{`当サイト限定の`}<a parentName="p" {...{
        "href": "https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`特設サイト`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0%22/",
        "alt": "''"
      }}></img>{`から買うと`}<strong parentName="p">{`プレミア価格で購入できるため必ず活用したい`}</strong>{`。【ID: `}<b>{`dyna204cls`}</b>{` PASS: `}<b>{`T8Y7GRSV`}</b>{` 】。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://linksynergy.jrs5.com/deeplink?id=xsv0FDnJs1M&mid=36508&murl=https%3A%2F%2Fdynabook.com%2Fdirect%2Fmobile-note-pc%2Frz-series.html" target="_blank" rel="nofollow noopener">Dynabook RZ</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "NEC--スタイリッシュなモバイルノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#NEC--%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AA%E3%83%83%E3%82%B7%E3%83%A5%E3%81%AA%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "NEC  スタイリッシュなモバイルノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NEC | スタイリッシュなモバイルノート`}</h3>
    <p>{`Dynabook、富士通と共に検討したいのがNECである。ビジネス向けのLavie Pro MobileはDynabookほどの堅牢性や富士通ほど軽量というわけではないが、`}<strong parentName="p">{`スタイリッシュなボディでLTEも搭載可能、20時間の長時間バッテリーと完成度が非常に高い`}</strong>{`。`}</p>
    <p>{`インターフェースも豊富に揃え、プライバシーアラート機能により覗き見を防止するなどビジネスに必要な機能がばっちりと備わっている。国内シェアトップのパソコンメーカーに恥じないフラッグシップモデルと言える。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fxc%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" />LAVIE NEXTREME</a>
    </SmallBox>
    <h3 {...{
      "id": "VAIO--外出が多いビジネスマンへ究極の125インチPC",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#VAIO--%E5%A4%96%E5%87%BA%E3%81%8C%E5%A4%9A%E3%81%84%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%9E%E3%83%B3%E3%81%B8%E7%A9%B6%E6%A5%B5%E3%81%AE125%E3%82%A4%E3%83%B3%E3%83%81PC",
        "aria-label": "VAIO  外出が多いビジネスマンへ究極の125インチPC permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VAIO | 外出が多いビジネスマンへ究極の12.5インチPC`}</h3>
    <p>{`元々ソニーであったがVAIO株式会社として切り離されて以来、ビジネス向けを主な顧客層としてパソコンを販売している。`}</p>
    <p>{`インターフェースの充実、堅牢性、軽量性などハードウェア面はもともと定評があるが、`}<strong parentName="p">{`13.3インチより僅かに小さい12.5インチモデルを主力として展開`}</strong>{`していることが特徴である。`}</p>
    <p>{`実際企画やマーケティングなど海外とのやり取りが多い人や、通勤でノートパソコンを持ち歩く人はこのサイズを好んで使っている。このサイズは文字が小さくなりすぎるため人を選ぶが、下記でレビューも行っているので参考にして欲しい。`}</p>
    <Card {...props} title="12.5インチノートパソコンのサイズ感とおすすめの機種" description="12.5インチのサイズ感やVAIO SX12をはじめとするおすすめの機種を話します。" image="cultramobile" path="/mobile12-5/" mdxType="Card" />
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=596216.7&type=3&subid=0&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIOストア</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.7&type=3&subid=0" />
    </SmallBox>
    <h3 {...{
      "id": "Mouse-Pro--マウスコンピュータの超軽量モデル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Mouse-Pro--%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%81%AE%E8%B6%85%E8%BB%BD%E9%87%8F%E3%83%A2%E3%83%87%E3%83%AB",
        "aria-label": "Mouse Pro  マウスコンピュータの超軽量モデル permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mouse Pro | マウスコンピュータの超軽量モデル`}</h3>
    <p>{`最新のプロセッサーをいち早く搭載しているあたりがパソコン専業メーカーらしいマウスコンピュータの法人向けノートパソコン。`}</p>
    <p>{`20時間以上のバッテリーと1kgを切る軽量さを兼ね備えており、サポートも24時間/365日でいざという時も安全である。`}</p>
    <p>{`デザインはそれほど良いとは思えないが、法人向けのためそこは文句は言えないだろう。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fr%2Fra3043054%2F%3Fintid%3Dbnap0124d240603" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt="" />Mouse Pro</a>
    </SmallBox>
    <h3 {...{
      "id": "Panasonicレッツノート--圧倒的な堅牢性",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Panasonic%E3%83%AC%E3%83%83%E3%83%84%E3%83%8E%E3%83%BC%E3%83%88--%E5%9C%A7%E5%80%92%E7%9A%84%E3%81%AA%E5%A0%85%E7%89%A2%E6%80%A7",
        "aria-label": "Panasonicレッツノート  圧倒的な堅牢性 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panasonic(レッツノート) | 圧倒的な堅牢性`}</h3>
    <p>{`パナソニックのパソコン、凹凸のあるデザインからも分かるが`}<strong parentName="p">{`まず間違いなく衝撃には最も強いであろう。デザインを犠牲にして実を取った形`}</strong>{`である。`}</p>
    <p>{`また光学式ドライブを搭載でき、VGA端子も付属するなど最新のビジネス現場よりは少し遅れている研究・教育・医療現場などで使用されていることが多い。`}</p>
    <p><strong parentName="p">{`デザインはダサいがデータをしっかり守り、IT化が遅れた現場においても力を発揮する`}</strong>{`のがレッツノートの特徴である。もちろんビジネスパソコンとしても非常に優秀であることは間違いはない。`}</p>
    <p>{`ノートパッドが丸く特徴的だが、好きな人にはたまらないらしい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899682" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899682" height="1" width="1" border="0" />Let's Note</a>
    </SmallBox>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`ビジネス用途のノートパソコンについて解説した。軽量・堅牢・高速でどこでも使える生活PCを手に入れて、毎日の生産性を是非向上させて欲しい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      